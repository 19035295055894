import { Controller } from "@hotwired/stimulus"

export default class ModalController extends Controller {
  connect() {
    const autoOpen = this.element.dataset.modalAutoOpen === "true"

    if (autoOpen) {
      this.open()
    }
  }

  open() {
    this.scrollPosition = window.scrollY
    document.body.classList.add("no-scroll")
    document.body.style.top = `-${this.scrollPosition}px`
    this.element.classList.remove("hidden")
  }

  close() {
    document.body.classList.remove("no-scroll")
    window.scrollTo({
      left: 0,
      top: this.scrollPosition,
      behavior: "instant",
    })
    this.element.classList.add("hidden")
  }
}
