import { baseDomain } from "@helpers/url.js"

/**
 * Set a cookie with a given name, value and expiration in days. It
 * uses the base domain and the root path. It automatically encodes values but JSON values need to be manually
 * stringified before calling this function.
 * @param {String} name the cookie name
 * @param {String} value the cookie value
 * @param {Number} expirationInDays the expiration in days
 */
export const setCookie = (name, value, expirationInDays) => {
  const domain = baseDomain(new URL(location.href))
  const date = new Date()
  date.setTime(date.getTime() + (expirationInDays * 24 * 60 * 60 * 1000))
  const expires = date.toUTCString()
  document.cookie = name + "=" + encodeURIComponent(value) + ";expires=" + expires + `;domain=.${domain};path=/`
}

/**
 * It returns the value from the cookie with the given name value or
 * undefined if the cookie is not found. JSON values need to be handled manually.
 * @param {String} name the cookie name
 * @returns {String | null} the cookie value
 */
export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const [, cookieData] = value.split(`; ${name}=`)

  if (!cookieData) return null

  const [cookieValue] = cookieData.split(";")
  return decodeURIComponent(cookieValue)
}

