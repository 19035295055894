/**
 * Remove empty properties from an object based on the predicate function.
 * It returns a copy of the object and does not mutate the original object.
 * @param {object} obj
 * @param {function} isEmpty predicate function to determine if a value is empty, defaults to null or undefined
 * @returns {object} the object with empty properties removed
 */
export const removeEmptyProperties = (obj, isEmpty = (value) => [undefined, null].includes(value)) => (
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => !isEmpty(value)),
  )
)
