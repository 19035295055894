import { Controller } from "@hotwired/stimulus"

export class HotjarExperimentViewedController extends Controller {
  static values = {
    experimentsViewed: Array,
  }

  connect() {
    for (const [experimentName, experimentVariation] of this.experimentsViewedValue) {
      window.hj?.("event", `(experiment_viewed) ${experimentName}__${experimentVariation}`)
    }
  }
}
