const UTM_PARAM_NAMES = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "gclid",
  "fbclid",
]

/**
 * Returns the UTM parameters from the URL.
 * @param {URL} url
 */
export const getUtmParameters = (url) => {
  const caseInsensitiveSearchParams = new URLSearchParams(
    Array.from(url.searchParams, ([key, value]) => [key.toLowerCase(), value]),
  )

  // Do not try to make this dynamic by checking for the `utm_` prefix.
  // That opens the door to dynamic properties which translates into database columns in our warehouse.
  return UTM_PARAM_NAMES.reduce(
    (acc, key) => ({
      ...acc,
      [key]: caseInsensitiveSearchParams.get(key),
    }),
    {},
  )
}

/**
 * Returns the base domain from a URL. If the domain is not a Circle Medical domain, it returns the full domain
 * as we cannot set cookies in the parent domain in most cases.
 * @param url URL
 * @returns {string} the base domain
 */
export const baseDomain = (url) => {
  if (!url.hostname.includes("circlemedical")) return url.hostname
  return url.hostname.split(".").reverse().splice(0,2).reverse().join(".")
}
