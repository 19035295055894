import { Controller } from "@hotwired/stimulus"

export default class ModalTriggerController extends Controller {
  static outlets = ["modal"]

  connect() {
    this.element.addEventListener("click", () => this.openModal())
  }

  openModal() {
    this.modalOutlet.open()
  }
}
