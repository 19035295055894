import { Controller } from "@hotwired/stimulus"
import { track } from "./analytics"

export class ButtonClickedController extends Controller {
  static values = {
    action: String,
    component: String,
  }

  connect() {
    this.element.addEventListener("click", () => this.trackButtonClicked())
  }

  trackButtonClicked() {
    track("Button Clicked", {
      action: this.actionValue,
      component: this.componentValue,
      path: window.location.pathname,
      url: window.location.href,
    })
  }
}