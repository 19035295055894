import { getCookie, setCookie } from "@client/cookies.js"
import { getUtmParameters } from "@helpers/url"
import { identify } from "@client/analytics"
import { removeEmptyProperties } from "@helpers/object.js"

/**
 * Records the marketing attribution using UTM parameters
 * if they are present in the URL.
 *
 * This will be used later on in the booking flow to attribute
 * the booking to the right marketing channel.
 */
export const recordAttribution = () => {
  if (getCookie("utm-params")) return

  const utmParams = getUtmParameters(new URL(window.location.href))

  if (Object.keys(removeEmptyProperties(utmParams)).length > 0) {
    identify(undefined, utmParams)
    setCookie("utm-params", JSON.stringify(utmParams), 30)
  }
}

/**
 * Sets up the Google Tag Manager (GTag) script and returns the gtag function.
 * @returns {GTag}
 */
export const setupGTag = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }

  appendScriptTag(createScriptTag(`https://www.googletagmanager.com/gtag/js?id=${import.meta.env.PUBLIC_GOOGLE_TAG_ID}`))

  gtag("js", new Date())
  gtag("config", import.meta.env.PUBLIC_GOOGLE_TAG_ID, { anonymize_ip: true })

  return gtag
}

/**
 * Sets up the Facebook Pixel script and returns the fbq function.
 * @returns {FBQ}
 */
export const setupFacebookPixel = () => {
  // refactored from the original snippet https://developers.facebook.com/docs/meta-pixel/get-started#base-code
  const initial = []
  initial.loaded = false
  initial.version = "2.0"
  initial.queue = []

  window.fbq = window.fbq || initial
  // Removes the duplicate pixel warning
  window._fbq = window._fbq || window.fbq

  function fbq() {
    if(window.fbq.callMethod) {
      window.fbq.callMethod.apply(window.fbq, arguments)
    } else {
      window.fbq.queue.push(arguments)
    }
  }

  initial.push = fbq

  fbq("init", import.meta.env.PUBLIC_FACEBOOK_PIXEL_ID)
  fbq("track", "PageView")

  appendScriptTag(createScriptTag("https://connect.facebook.net/en_US/fbevents.js"))

  return fbq
}

/**
 * Sets up the Bing Pixel script and returns the UET function.
 * @returns {Promise<UET>}
 */
export const setupBingPixel = () => {
  return new Promise((resolve) => {
    window.uetq = window.uetq || []
    const scriptTag = createScriptTag("//bat.bing.com/bat.js")
    const onReady = function() {
      const state = this.readyState
      if (state === "loaded" || state === "complete") {
        const options = { ti: import.meta.env.PUBLIC_BING_PIXEL_ID, enableAutoSpaTracking: true, q: window.uetq }
        window.uetq = new window.UET(options)
        window.uetq.push("pageLoad")
        scriptTag.onload = null
        scriptTag.onreadystatechange = null
        resolve(window.uetq)
      }
    }
    scriptTag.onload = onReady
    scriptTag.onreadystatechange = onReady

    appendScriptTag(scriptTag)
  })
}

const createScriptTag = (src) => {
  const scriptTag = document.createElement("script")
  scriptTag.src = src
  scriptTag.async = true
  return scriptTag
}

const appendScriptTag = (scriptTag) => document.getElementsByTagName("head")[0].append(scriptTag)
